<template>
    <v-row no-gutters>
        <v-col cols="12">
            <!-- hierarchical navigation -->
            <v-row justify="start" class="mt-2 mx-4">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <p class="text-caption text-start">
                    <router-link :to="{ label: 'dashboard' }">Dashboard</router-link> &gt;
                    <router-link :to="{ label: 'user-account-list' }">Accounts</router-link> &gt;
                    <router-link :to="{ label: 'account-dashboard', params: { accountId: this.$route.params.accountId } }">{{ accountName }}</router-link>
                </p>
                </v-col>
            </v-row>
            <v-row justify="center" class="py-5 mt-2 px-10">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <h1 class="text-h6 font-weight-light text-center">Create new email audience</h1>
                <!-- <p class="text-caption text-left mt-8">This will create an independent form for storing your data.</p> -->
                </v-col>
            </v-row>
            <v-row justify="center" class="py-5 px-10">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                    <v-card tile elevation="4" class="pa-0">
                        <v-toolbar short flat color="white">
                            <v-toolbar-title class="green--text">Create new email audience</v-toolbar-title>
                        </v-toolbar>
                        <v-card-text class="px-5">
                        <v-form @submit.prevent="onSubmitForm"> <!-- @keyup.enter.native="onSubmitForm" -->
                            <!-- <template v-if="website">
                                <p>This form will be linked to the website
                                    <router-link :to="{ name: 'account-edit-website', params: { accountId: this.$route.params.accountId, websiteId: website.id } }">{{ website.label }}</router-link>.
                                    You can update the website content from this form.</p>
                            </template> -->
                            <!-- <v-tooltip top>
                                <template #activator="{ on, attrs }">
                                    <p v-bind="attrs" v-on="on">What is a form label? <font-awesome-icon :icon="['fas', 'info-circle']" class="blue--text text-lighten-2"/></p>
                                </template>
                                <p>When you create a form, we generate a unique identifier for it. When a form is attached to a server, it is generally accessible at <span class="text-no-wrap">/mnt/form/&lt;id&gt;</span>. When you access the storage form via file server, we create a convenient link from your home directory to the form mount point at <span class="text-no-wrap">~/&lt;account-label&gt;/&lt;form-label&gt;</span>. This makes it easier to recognize and access forms that you have access to. A form label must be unique within the account.</p>
                            </v-tooltip>
                            <v-text-field
                                ref="formLabelInput"
                                v-model="newFormLabel"
                                label="Form label"
                                :rules="newFormLabelRules"
                                validate-on-blur
                                color="teal darken-2"
                                required
                                hint="The directory name to use for the form (you can change this later)"
                                type="text"
                                outlined
                            >
                            </v-text-field> -->
                            <p>
                                An email audience is a set of contacts who have something in common, or that you want to address the same way.
                                An email audience is dynamic -- contacts are automatically included or excluded from an audience based on criteria
                                that you specify.
                            </p>
                            <v-text-field
                                ref="emailAudienceLabelInput"
                                v-model="newEmailAudienceLabel"
                                label="Label"
                                :rules="newEmailAudienceLabelRules"
                                validate-on-blur
                                color="teal darken-2"
                                type="text"
                                outlined
                                dense
                                hint="Summarize the match criteria for this audience. For example, 'English-speakers age 26-45 participating in the alpha program for product XYZ'."
                            >
                            </v-text-field>
                        </v-form>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn elevation="4" class="green white--text" @click="onSubmitForm" :disabled="!isFormComplete">
                                <span>Create</span>
                            </v-btn>
                            <v-btn text color="grey" @click="cancel">
                                <span>Cancel</span>
                            </v-btn>
                            <v-spacer></v-spacer>
                        </v-card-actions>
                    </v-card>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<style>
.row > .v-input:first-of-type {
    margin-right: 4px;
}
.row > .v-input + .v-input {
    margin-left: 4px;
    margin-right: 4px;
}
.row > .v-input:last-of-type {
    margin-left: 4px;
}
</style>

<script>
import { mapState } from 'vuex';
// import { isValidEmail /* , compact */ } from '@/sdk/input';

export default {
    data: () => ({
        domainList: [],

        error: null,
        website: null,
        // create form
        createEmailAudienceForm: null,
        // newFormLabel: null,
        // newFormLabelRules: [
        //     (v) => !!v || 'Display label is required',
        //     (v) => !v || isValidFormLabel(compact(v)) || 'Form label is required',
        // ],
        newEmailAudienceLabel: null,
        newEmailAudienceLabelRules: [
            (v) => !!v || 'Label is required',
        ],
    }),
    computed: {
        ...mapState({
            session: (state) => state.session,
        }),
        isFormComplete() {
            return typeof this.newEmailAudienceLabel === 'string' && this.newEmailAudienceLabel.trim().length > 0;
        },
        accountName() {
            return this.account?.name ?? 'Unknown';
        },
    },
    methods: {
        async loadAccount() {
            try {
                this.error = false;
                this.$store.commit('loading', { loadAccount: true });
                const response = await this.$client.account(this.$route.params.accountId).currentAccount.get();
                console.log(`account/dashboard.vue: response ${JSON.stringify(response)}`);
                if (response) {
                    this.account = response;
                } else {
                    // TODO: redirect back to account list? show a not found message?
                }
            } catch (err) {
                console.error('failed to load account', err);
                this.error = true;
            } finally {
                this.$store.commit('loading', { loadAccount: false });
            }
        },
        onSubmitForm() {
            if (!this.isFormComplete) {
                return;
            }
            this.createEmailAudience();
        },
        cancel() {
            // this.$emit('cancel');
            this.$router.go(-1);
        },
        async createEmailAudience() {
            try {
                this.error = false;
                this.$store.commit('loading', { createEmailAudience: true });
                console.log('createEmailAudience');
                const request = {
                    label: this.newEmailAudienceLabel,
                };
                // if (this.website) {
                //     request.website_id = this.website.id;
                // }
                const response = await this.$client.account(this.$route.params.accountId).emailAudience.create(request);
                console.log('createEmailAudience response: %o', response);
                const { id, error } = response;
                if (id) {
                    // const form = { id, ...request };
                    // this.list.push(form); // this SHOULD work (https://vuejs.org/v2/guide/list.html#Mutation-Methods) but doesn't work.
                    this.$router.replace({ name: 'account-edit-email-audience', params: { accountId: this.$route.params.accountId, emailAudienceId: id } });
                } else if (error) {
                    this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to create email audience' });
                    this.error = error;
                } else {
                    this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to create email audience' });
                    this.error = 'Request failed';
                }
                // this.$emit('created', { id });
            } catch (err) {
                console.error('failed to create email audience', err);
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to create email audience' });
            } finally {
                this.$store.commit('loading', { createEmailAudience: false });
            }
        },
    },
    mounted() {
        this.loadAccount();
    },
};
</script>
